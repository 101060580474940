import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import AgendaGroup from 'modules/events/groups/AgendaGroup';
import CventGroup from 'modules/events/groups/CventGroup';
import NavigationConfigGroup from 'modules/events/groups/NavigationConfigGroup/NavigationConfigGroup';
import OptionalInformationsGroup from 'modules/events/groups/OptionalInformationsGroup';
import PrimaryInformationsGroup from 'modules/events/groups/PrimaryInformationsGroup';
import RestrictedAccessGroup from 'modules/events/groups/RestricedAccessGroup/RestrictedAccessGroup';
import SessionInformationGroup from 'modules/events/groups/SessionInformationGroup';
import SpeakersGroup from 'modules/events/groups/SpeakersGroup';
import TimeSlotGroup from 'modules/events/groups/TimeSlotGroup';
import useDetailsStep from 'modules/events/routes/CreateHybridMeeting/Steps/DetailsStep/DetailsStep.hook';
import Box from 'yoda-ui/components/Box';

const DetailsStep: FC = () => {
  const flags = useFlags();

  useDetailsStep();
  return (
    <Box>
      <TimeSlotGroup />
      <SessionInformationGroup />
      <NavigationConfigGroup/>
      <PrimaryInformationsGroup />
      {
        flags.eventsCweb368EnableCventSection
        && <CventGroup />
      }
      <SpeakersGroup />
      <OptionalInformationsGroup />
      <AgendaGroup />
      <RestrictedAccessGroup />
    </Box>
  );
};

export default DetailsStep;
