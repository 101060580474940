import { useEffect, useState } from 'react';
import useCventGroupConfig from './CventGroup.config';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useCventGroup = () => {
  const { cventGroupConfig } = useCventGroupConfig();
  const { setValue, useWatchField } = useYodaForm();
  const [open, setOpen] = useState(false);
  const cventTitle = useWatchField(commonFields.cventTitle);
  const cventSourceUrl = useWatchField(commonFields.cventSourceUrl);
  const [isCventHasData, setIsCventHasData] = useState(false);

  useEffect(() => {
    if (cventTitle?.value && cventSourceUrl?.value) {
      setIsCventHasData(true);
    }
  }, [cventTitle, cventSourceUrl]);

  const handleModalSubmit = ({ title, sourceUrl }: { title: string; sourceUrl: string }) => {
    setValue(commonFields.cventTitle, title);
    setValue(commonFields.cventSourceUrl, sourceUrl);
  };

  const handleReset = () => {
    setValue(commonFields.cventTitle, '');
    setValue(commonFields.cventSourceUrl, '');
    setIsCventHasData(false);
  };

  return {
    cventGroupConfig,
    handleModalSubmit,
    open,
    setOpen,
    handleReset,
    isCventHasData,
  };
};

export default useCventGroup;
