import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CardForm from 'components/CardForm';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import Agenda from 'modules/events/components/Agenda';
import CardImage from 'modules/events/components/CardImage';
import useAgenda from 'modules/events/groups/AgendaGroup/AgendaGroup.hook';
import Box from 'yoda-ui/components/Box';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const AgendaGroup: FC = () => {
  const { t } = useTranslation();
  const { formConfig, handleDropFile } = useAgenda();

  return (
    <Box>
      <Box paddingBottom={ YodaSpacing.medium }>
        <FormGroup title={ t('events.agendaGroupTitle') } groupName={ CommonGroups.agenda }>
          <Agenda { ...formConfig.agenda } />
        </FormGroup>
      </Box>
      <Box fontWeight={ YodaFontWeight.light } paddingBottom={ YodaSpacing.medium }>
        <CardForm title={ t('events_agenda_file_section_title') }>
          <CardImage
            handleFiles={ handleDropFile }
            { ...formConfig.agendaFile }
          />
          <Box marginBottom={ YodaSpacing.large }>
            <TextYoda { ...formConfig.agendaFileTitle } />
          </Box>
          <Box marginBottom={ YodaSpacing.large }>
            <TextYoda { ...formConfig.agendaFileDescription } />
          </Box>
        </CardForm>
      </Box>
    </Box>
  );
};

export default AgendaGroup;
