import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import AgendaGroup from 'modules/events/groups/AgendaGroup';
import CventGroup from 'modules/events/groups/CventGroup';
import NavigationConfigGroup from 'modules/events/groups/NavigationConfigGroup/NavigationConfigGroup';
import OptionalInformationsGroup from 'modules/events/groups/OptionalInformationsGroup';
import PrimaryInformationsGroup from 'modules/events/groups/PrimaryInformationsGroup';
import SpeakersGroup from 'modules/events/groups/SpeakersGroup';
import TimeSlotGroup from 'modules/events/groups/TimeSlotGroup';
import UserManagementGroup from 'modules/events/groups/UserManagementGroup/UserManagementGroup';
import useDetailsStep from 'modules/events/routes/CreateOnlineMeeting/Steps/DetailsStep/DetailsStep.hook';
import Box from 'yoda-ui/components/Box';

const DetailsStep: FC = () => {
  const flags = useFlags();

  useDetailsStep();
  return (
    <Box>
      <TimeSlotGroup />
      <NavigationConfigGroup/>
      <UserManagementGroup />
      <PrimaryInformationsGroup />
      {
        flags.eventsCweb368EnableCventSection
        && <CventGroup />
      }
      <SpeakersGroup />
      <OptionalInformationsGroup />
      <AgendaGroup />
    </Box>
  );
};

export default DetailsStep;
