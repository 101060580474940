import { Buffer } from 'buffer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLazyGetSecretQuery } from 'basics/graphql/queries/getSecret';
import { EventStatus, HostProvider, HybridEvent, OnlineEvent, Presence, Source } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import { getEditRouteFromEvent, getManageEventRoute, isDraft } from 'modules/events/basics/utils/events.utils';
import { EventsListItemAction } from 'modules/events/components/EventsList/EventsListItem/EventsListItem.types';
import { useSendEventInvitationMutation } from 'modules/events/graphql/mutations/sendEventInvitation';
import { useSyncAllParticipantsMutation } from 'modules/events/graphql/mutations/syncAllParticipants';
import { useUpdateZoomDataMutation } from 'modules/events/graphql/mutations/updateZoomData';
import useExportEventParticipantDataQuery, { ResultType } from 'modules/events/graphql/queries/exportEventParticipantData';
import { YodaColors } from 'yoda-ui/yodaTheme';

type UseEventsListItem = {
  event: Event;
};

const useEventsListItem = ({ event }: UseEventsListItem) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const navigate = useNavigate();
  const [linkDialogIsOpen, setLinkDialogIsOpen] = useState(false);
  const [unpublishConfirmationIsOpen, setUnpublishConfirmationIsOpen] = useState(false);
  const [updateZoomData] = useUpdateZoomDataMutation();
  const [syncAllParticipants] = useSyncAllParticipantsMutation();
  const [currentStatus, setCurrentStatus] = useState(event.status);
  const [providerData, setProviderData] = useState((event.data as OnlineEvent | HybridEvent)?.provider);
  const { queryGetSecret } = useLazyGetSecretQuery();
  const [ceaReportBaseUrl, setCeaReportBaseUrl] = useState('');
  const [ceaReportFilterClause, setCeaReportFilterClause] = useState('');

  const closeLinkDialog = useCallback(() => {
    setLinkDialogIsOpen(false);
  }, []);

  const handleEditClick = useCallback(() => {
    navigate(getEditRouteFromEvent(event));
  }, [event, navigate]);

  useEffect(() => {
    const getCeaSecrets = async () => {
      const { data: { getSecret: baseUrl } } = await queryGetSecret({ variables: { key: 'CEA_REPORT_BASE_URL' } });
      const { data: { getSecret: filterClause } } = await queryGetSecret({ variables: { key: 'СEA_REPORT_FILTER_CLAUSE' } });
      setCeaReportBaseUrl(baseUrl);
      setCeaReportFilterClause(filterClause);
    };

    getCeaSecrets();
  }, [queryGetSecret]);

  const handleCeaReportClick = useCallback(() => {
    window.open(`${ceaReportBaseUrl + ceaReportFilterClause}'${event.externalId}'`, '_blank');
  }, [ceaReportBaseUrl, ceaReportFilterClause, event.externalId]);

  const [executeMutation] = useSendEventInvitationMutation();

  const handleEditPublishedClick = useCallback(() => {
    if (!isDraft(event.status)) {
      navigate(getManageEventRoute(event));
    }
  }, [event, navigate]);

  const handleShowLinksClick = useCallback(async () => {
    if (
      (event.presence === Presence.online || event.presence === Presence.hybrid)
      && (event.data as OnlineEvent | HybridEvent).provider?.host === HostProvider.zoom
    ) {
      const freshProvider = await updateZoomData(event._id);
      setProviderData(freshProvider);
    }
    setLinkDialogIsOpen(true);
  }, [event._id, event.presence, event.data, updateZoomData]);

  const triggerDownload = (url: string) => {
    const eventClick = new MouseEvent('click');
    const el = document.createElement('a');
    el.setAttribute('href', url);
    el.setAttribute('download', `export-event-${new Date().getTime()}`);
    el.dispatchEvent(eventClick);
    URL.revokeObjectURL(url);
  };

  const onCompleteCallback = (data: ResultType) => {
    const url = URL.createObjectURL(new Blob(
      [(
        data?.exportEventParticipantData?.buffer
          ? Buffer.from(data.exportEventParticipantData.buffer, 'base64')
          : null
      ) as unknown as Buffer],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    ));
    triggerDownload(url);
  };

  const { error, exportData } = useExportEventParticipantDataQuery(onCompleteCallback);

  const useExportParticipantsClick = useCallback(async () => {
    toast.info(t('event_export_in_progress'));
    await exportData(
      {
        variables: {
          eventId: event._id,
        },
      },
    );

    if (error) {
      toast.error(t('event_export_error'));
    } else {
      toast.success(t('event_export_success'));
    }
  }, [error, event._id, exportData, t]);

  const menuItemConfigs = useMemo(() => {
    const isPublished = currentStatus === EventStatus.published;
    const showSendInvitations = !!(event.data as OnlineEvent)?.biogenLincConfig?.showSendInvitations;
    const invitationSent = !!(event.data as OnlineEvent)?.invitationSent;
    const now = new Date();
    const eventStartDate = event.data.session?.startDate ? new Date(event.data.session?.startDate) : false;

    return [
      {
        key: EventsListItemAction.showEventLinks,
        label: t('eventList_menuItemConfigs_eventLinks_label'),
        onClick: () => handleShowLinksClick(),
        hideItem: isDraft(event.status),
      },
      {
        key: EventsListItemAction.editPublishedEvent,
        label: t('eventList_menuItemConfigs_editPublishedEvent_label'),
        onClick: handleEditPublishedClick,
        hideItem: isDraft(event.status),
      },
      {
        key: EventsListItemAction.editEvent,
        label: t('eventList_menuItemConfigs_editEvent_label'),
        onClick: handleEditClick,
      },
      {
        key: EventsListItemAction.ceaReport,
        label: t('eventList_menuItemConfigs_ceaReport_label'),
        onClick: handleCeaReportClick,
        hideItem: !event.externalId || !isPublished || !flags.eventsCweb422EnableLinkToEventCeaReporting,
      },
      {
        key: EventsListItemAction.exportParticipants,
        label: t('eventList_menuItemConfigs_exportParticipants_label'),
        onClick: useExportParticipantsClick,
      },
      {
        key: EventsListItemAction.sendInvitation,
        label: t('eventList_menuItemConfigs_sendInvitation_label'),
        onClick: () => executeMutation(event?._id ? event?._id.toString() : ''),
        hideItem: isDraft(event.status) || !(showSendInvitations && !invitationSent),
      },
      {
        key: EventsListItemAction.unpublishEvent,
        label: t('eventList_menuItemConfigs_unpublishEvent_label'),
        onClick: () => setUnpublishConfirmationIsOpen(true),
        hideItem: eventStartDate
          ? eventStartDate > now || !isPublished
          : !isPublished,
      },
      {
        key: EventsListItemAction.unpublishEvent,
        label: t('eventList_menuItemConfigs_bulkSync_label'),
        onClick: () => syncAllParticipants(event._id),
        hideItem: !isPublished || event.source !== Source.veeva,
      },
    ];
  }, [
    currentStatus,
    event.data,
    event.status,
    event.source,
    event._id,
    event.externalId,
    t,
    flags.eventsCweb422EnableLinkToEventCeaReporting,
    handleEditPublishedClick,
    handleEditClick,
    useExportParticipantsClick,
    handleShowLinksClick,
    executeMutation,
    syncAllParticipants,
    handleCeaReportClick,
  ]);

  const eventStatusBadgeMap: Record<EventStatus, { label: string; bgColor: string; color: string }> = {
    [EventStatus.draft]: {
      label: t('event_status_draft'),
      bgColor: YodaColors.warningLight,
      color: YodaColors.warning,
    },
    [EventStatus.ready]: {
      label: t('event_status_draft'),
      bgColor: YodaColors.warningLight,
      color: YodaColors.warning,
    },
    [EventStatus.published]: {
      label: t('event_status_published'),
      bgColor: YodaColors.greenSuccessLight,
      color: YodaColors.greenSuccessDark,
    },
    [EventStatus.unpublished]: {
      label: t('event_status_unpublished'),
      bgColor: YodaColors.redErrorLight,
      color: YodaColors.redErrorDark,
    },
  };

  return {
    eventStatusBadgeMap,
    eventEditRoute: isDraft(event.status) ? getEditRouteFromEvent(event) : getManageEventRoute(event),
    menuItemConfigs,
    linkDialogIsOpen,
    unpublishConfirmationIsOpen,
    setUnpublishConfirmationIsOpen,
    closeLinkDialog,
    currentStatus,
    setCurrentStatus,
    providerData,
  };
};

export default useEventsListItem;
