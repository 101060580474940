import format from 'date-fns/format';
import { Session } from 'generated/event.types';
import { TimeSlot } from 'modules/events/basics/types/events.types';
import { transformTimeSlotToSession } from 'modules/events/basics/utils/events.transformers';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker/DateRangePicker.types';

const useAgenda = () => {
  const parseDateWithoutTimezone = (dateStr: string) => {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const transformToState = (
    { id, dateRange, startTime, endTime, title, description }:
    { id: string; dateRange: string; startTime: string; endTime: string; title: string; description: string },
  ) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      id,
      title,
      description,
      session: transformTimeSlotToSession({
        dateRange: [parseDateWithoutTimezone(dateRange), parseDateWithoutTimezone(dateRange)] as DateRange,
        timeZone: userTimezone,
        startTime,
        endTime,
      } as TimeSlot),
    };
  };

  const transformToCard = (
    { id, title, session: { endDate, startDate } }:
    { id: string; title: string; session: Session },
  ) => {
    const entryDate = format(new Date(startDate), 'dd/MM/yyyy');
    const startTime = format(new Date(startDate), 'HH:mm');
    const endTime = format(new Date(endDate), 'HH:mm');
    return {
      id,
      property1: `${entryDate} (${startTime}-${endTime})`,
      property2: title,
    };
  };

  return {
    transformToCard,
    transformToState,
  };
};

export default useAgenda;
