import { string } from 'yup';

const useManageCventModalSchema = () => {
  return {
    title: string()
      .nullable()
      .required(),

    sourceUrl: string()
      .nullable()
      .required()
      .url('please provide a valid URL'),
  };
};

export default useManageCventModalSchema;
