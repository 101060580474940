import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { FileExtension } from 'basics/types/files.types';
import { AssetTarget, AssetType, Presence } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import useOptionalInformationsSchema from 'modules/events/groups/OptionalInformationsGroup/OptionalInformationsGroup.schema';
import { OptionalInformationsType } from 'modules/events/groups/OptionalInformationsGroup/OptionalInformationsGroup.types';
import eventState from 'modules/events/states/events.states';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';

const useOptionalInformationsConfig = () => {
  const { t } = useTranslation();
  const optionalInformationsSchema = useOptionalInformationsSchema();
  const snap = useSnapshot(eventState) as EventStateType;

  const optionalInformationsConfig: OptionalInformationsType = {
    teaser: {
      name: commonFields.teaser,
      title: t('event_field_teaser_title'),
      teaserFormConfig: {
        btnLabel: t('events_teaser_dropzone_action_label'),
        btnIcon: Icons.upload,
        maxFileSize: 5242880,
        maxFilesNumber: 1,
        extensionsAllowed: [FileExtension.mp4],
        message: t('events_teaser_dropzone_message'),
        label: t('events_teaser_input_label'),
        name: commonFields.teaser,
        assetTarget: AssetTarget.teaser,
        assetType: AssetType.video,
      },
      listConfig: {
        noItemLabel: t('asset_upload_no_image_label'),
        title: t('asset_upload_teaser_title'),
      },
      validation: optionalInformationsSchema.teaser,
      defaultValue: snap.createEvent.data?.teaser || [],
    },
    testimonials: {
      name: commonFields.testimonials,
      testimonialFormConfig: {
        title: t('event_field_testimonial_title'),
        authorField: {
          formFieldName: 'author',
          label: t('event_field_testimonial_author_label'),
          placeholder: t('event_field_testimonial_author_placeholder'),
          disabled: false,
        },
        testimonyField: {
          formFieldName: 'testimony',
          label: t('event_field_testimonial_testimony_label'),
          placeholder: t('event_field_testimonial_testimony_placeholder'),
          multiline: true,
          rows: 5,
          richtext: true,
          disabled: false,
        },
      },
      listConfig: {
        title: t('testimonials_form_list_title'),
        noItemLabel: t('testimonials_list_empty'),
      },
      validation: optionalInformationsSchema.testimonials,
      defaultValue: snap.createEvent.data?.testimonials || [],
      disabled: false,
    },
    ...(eventState?.createEvent?.presence === Presence.online ? { recordingOptinLabel: {
      label: t('events_recording_optin_input_label'),
      name: commonFields.recordingOptinLabel,
      placeholder: t('events_recording_optin_input_placeholder'),
      multiline: true,
      rows: 5,
      required: false,
      defaultValue: snap.createEvent.data?.recordingOptinLabel,
      validation: optionalInformationsSchema.recordingOptinLabel,
    } } : {}),
    disclaimerText: {
      label: t('events_disclaimer_text_label'),
      name: commonFields.disclaimerText,
      placeholder: t('events_disclaimer_text_placeholder'),
      multiline: true,
      rows: 5,
      required: false,
      defaultValue: snap.createEvent.data?.disclaimerText,
      validation: optionalInformationsSchema.disclaimerText,
    },
    footerDisclaimerText: {
      label: t('events_footer_disclaimer_text_label'),
      name: commonFields.footerDisclaimerText,
      placeholder: t('events_footer_disclaimer_text_placeholder'),
      required: false,
      defaultValue: snap.createEvent.data?.footerDisclaimerText,
      validation: optionalInformationsSchema.footerDisclaimerText,
    },
    productInformationButtonLabel: {
      label: t('events_product_information_button_label'),
      name: commonFields.productInformationButtonLabel,
      placeholder: t('events_product_information_button_label_placeholder'),
      multiline: false,
      required: false,
      defaultValue: snap.createEvent.data?.productInformationButtonLabel/* || t('events_product_information_button_label_default_value') */,
      validation: optionalInformationsSchema.productInformationButtonLabel,
    },
    productInformationButtonLink: {
      label: t('events_product_information_button_link_label'),
      name: commonFields.productInformationButtonLink,
      placeholder: t('events_product_information_button_link_placeholder'),
      multiline: false,
      required: false,
      defaultValue: snap.createEvent.data?.productInformationButtonLink,
      validation: optionalInformationsSchema.productInformationButtonLink,
    },
    participantsSupportPhoneNumber: {
      label: t('events_participant_support_phone_number_label'),
      name: commonFields.participantsSupportPhoneNumber,
      placeholder: t('events_participant_support_phone_number_placeholder'),
      required: false,
      defaultValue: snap.createEvent.data?.participantsSupportPhoneNumber,
      validation: optionalInformationsSchema.participantsSupportPhoneNumber,
      disabled: false,
    },

    collectParticipantPhoneNumber: {
      label: t('events_collect_participant_phone_number_label'),
      name: commonFields.collectParticipantPhoneNumber,
      checked: snap.createEvent.data?.collectParticipantPhoneNumber || false,
    },

    collectPhoneNumberMandatory: {
      label: t('events_collect_phone_number_mandatory_label'),
      name: commonFields.collectPhoneNumberMandatory,
      checked: snap.createEvent.data?.collectPhoneNumberMandatory || false,
    },

    collectPhoneNumberUserConsent: {
      label: t('events_collect_phone_number_userConsent_label'),
      name: commonFields.collectPhoneNumberUserConsent,
      checked: snap.createEvent.data?.collectPhoneNumberUserConsent || false,
    },

    collectTextMessageUserConsent: {
      label: t('events_collect_text_message_userConsent_label'),
      name: commonFields.collectTextMessageUserConsent,
      checked: snap.createEvent.data?.collectTextMessageUserConsent || false,
    },

  };

  return {
    optionalInformationsConfig,
  };
};

export default useOptionalInformationsConfig;
