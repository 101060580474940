import { Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import VeevaInsciteSyncModal from './VeevaInsciteSyncModal/VeevaInsciteSyncModal';
import VeevaInsciteSyncTypeModal from './VeevaInsciteSyncTypeModal/VeevaInsciteSyncTypeModal';
import FormGroup from 'components/FormGroup';
import { VeevaInsciteSyncProvider } from 'contexts/veevaInsciteSync';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import useEventTesting from 'modules/events/components/EventTesting/EventTesting.hook';
import InviteesRegistrationPanel from 'modules/events/components/InviteesRegistrationPanel';
import { ActionButton, ButtonVariant, TextButton } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Divider from 'yoda-ui/components/Divider';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const EventTesting = () => {
  const { t,
    eventTestingConfig,
    providerFields,
    eventId,
    participants,
    setParticipants,
    lastTestAt,
    refreshListFunction,
    event,
    mailinatorUrl,
    pdfUrl,
    emailsPdfUrl,
    pdfUrlForVeeva,
    screenshotProgress,
    pdfLoading,
    emailsPdfLoading,
    veevaSyncModalIsOpen,
    setVeevaSyncModalIsOpen,
    veevaSyncTypeModalIsOpen,
    setVeevaSyncTypeModalIsOpen } = useEventTesting();

  const flags = useFlags();

  return (
    <>
      <YodaFormProvider { ...providerFields }>
        <FormGroup title={ t('event_testing_subsection_title') } groupName={ CommonGroups.testing }>
          <SelectYoda { ...eventTestingConfig.testUserList } />

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={ YodaSpacing.small }
            marginBottom={ YodaSpacing.medium }
          >
            <ActionButton
              icon={ Icons.mail }
              label={ t('event_testing_go_to_mailinator') }
              href={ mailinatorUrl }
              target='_blank'
              variant={ ButtonVariant.primary }
              disabled={ !mailinatorUrl }
            />
            <TextButton { ...eventTestingConfig.addPrivilegedUser }/>
            <TextButton { ...eventTestingConfig.testButton }/>
          </Box>
          <Divider />
          <FormGroup title={ t('events_testing_event_screenshot_validation_title') } groupName={ CommonGroups.eventPdf }>
            <Box display="flex" flexDirection="row">
              <Box>
                <TextButton { ...eventTestingConfig.generateEventPagesPdfButton }/>
              </Box>
              {
                flags.cweb372ScreenshotPdfSync
              && <Box marginLeft={ YodaSpacing.medium }>
                <TextButton { ...eventTestingConfig.insciteEventPagesSyncButton }/>
              </Box>
              }
              <Box marginLeft={ YodaSpacing.medium }>
                <ActionButton
                  icon={ Icons.donwload }
                  label={
                    pdfLoading
                      ? `${t('event_test_screenshot_generating_progress_label',
                        { screenshotProgress: screenshotProgress || 0 })}`
                      : t('event_testing_download_event_pages_pdf_button')
                  }
                  href={ pdfUrl || undefined }
                  target='_blank'
                  variant={ ButtonVariant.primary }
                  disabled={ !pdfUrl || pdfLoading || emailsPdfLoading }
                />
              </Box>
            </Box>
          </FormGroup>
          <FormGroup title={ t('events_testing_emails_screenshot_validation_title') } groupName={ CommonGroups.emailsPdf }>
            <Box display="flex" flexDirection="row">
              <Box>
                <TextButton { ...eventTestingConfig.generateEmailsPdfButton }/>
              </Box>
              {
                flags.cweb372ScreenshotPdfSync
              && <Box marginLeft={ YodaSpacing.medium }>
                <TextButton { ...eventTestingConfig.insciteEmailsSyncButton }/>
              </Box>
              }
              <Box marginLeft={ YodaSpacing.medium }>
                <ActionButton
                  icon={ Icons.donwload }
                  label={
                    emailsPdfLoading
                      ? `${t('event_test_screenshot_generating_progress_label',
                        { screenshotProgress: screenshotProgress || 0 })}`
                      : t('event_testing_download_emails_pdf_button')
                  }
                  href={ emailsPdfUrl || undefined }
                  target='_blank'
                  variant={ ButtonVariant.primary }
                  disabled={ !emailsPdfUrl || pdfLoading || emailsPdfLoading }
                />
              </Box>
            </Box>
          </FormGroup>
          {
            lastTestAt && (
              <InviteesRegistrationPanel
                participants={ participants || [] }
                seats={ 0 }
                eventId={ eventId as string }
                setParticipants={ setParticipants }
                e2eTestMode={ true }
                event={ event }
                refreshList={ refreshListFunction }
              />)
          }

        </FormGroup>
        {
          pdfUrlForVeeva && flags.cweb372ScreenshotPdfSync
          && <VeevaInsciteSyncProvider>
            <VeevaInsciteSyncTypeModal open={ veevaSyncTypeModalIsOpen }
              setOpen={ setVeevaSyncTypeModalIsOpen }
              setFormOpen={ setVeevaSyncModalIsOpen }
              pdfUrl={ pdfUrlForVeeva } />
            <VeevaInsciteSyncModal open={ veevaSyncModalIsOpen }
              setOpen={ setVeevaSyncModalIsOpen }
              pdfUrl={ pdfUrlForVeeva } />
          </VeevaInsciteSyncProvider>
        }
      </YodaFormProvider>
    </>
  );
};

export default EventTesting;
