export enum ParticipantsItemCollapseFields {
  accountId = 'accountId',
  collectedPhoneNumber = 'collectedPhoneNumber',
  institution = 'institution',
  invitationDate = 'invitationDate',
  mdmId = 'mdmId',
  phone = 'phone',
  specialty = 'specialty',
  zoomCallLink = 'zoomCallLink',
  registrationEmailSent = 'registrationEmailSent',
  invitationEmailSent = 'invitationEmailSent',
  reminder1week = 'reminder1week',
  reminder48h = 'reminder48h',
  reminder24h = 'reminder24h',
  reminder12h = 'reminder12h',
  reminder1h = 'reminder1h',
  reminderSMS = 'reminderSMS',
  cxEmailSent = 'cxEmailSent',
  externalEventParticipantStatus = 'externalEventParticipantStatus',
  attendedZoom = 'attendedZoom',
  attendedZoomDuration = 'attendedZoomDuration',
  guestCountryCode = 'guestCountryCode',
}
