import { assetFragment } from 'basics/graphql/fragments/commonData.fragments';
import {
  agendaFragment,
  biogenLincConfigFragment,
  cventConfigFragment,
  participantFragment,
  sessionFragment,
  testimonialFragment,
} from 'modules/events/graphql/fragments/commonData.fragments';
import { userFragment } from 'modules/events/graphql/fragments/event.fragments';
import { speakerFragment } from 'modules/events/graphql/fragments/speaker.fragments';

export const physicalEventDataFragment = `
  __typename
  coOrganizers {
    ${userFragment}
  }
  session {
    ${sessionFragment}
  }
  product
  program
  assets {
    cardImage {
      ${assetFragment}
    }
    programLogo {
      ${assetFragment}
    }
    agendaFile {
      ${assetFragment}
    }
  }
  speakers {
    ${speakerFragment}
  }
  testimonials {
    ${testimonialFragment}
  }
  agenda {
    ${agendaFragment}
  }
  biogenLincConfig {
    ${biogenLincConfigFragment}
  }
  cventConfig {
    ${cventConfigFragment}
  }
  participants {
    ${participantFragment}
  }
  userGroups
  disclaimerText
  footerDisclaimerText
  productInformationButtonLabel
  productInformationButtonLink
  participantsSupportPhoneNumber
  speakerSupportPhoneNumber
  collectParticipantPhoneNumber
  collectPhoneNumberMandatory
  collectPhoneNumberUserConsent
  collectTextMessageUserConsent
  agendaFileTitle
  agendaFileDescription
  invitationSent
  emailHeaderNote
  emailHeaderNoteEmailTypes
  emailFooterNote
  emailFooterNoteEmailTypes
  emailFooterDisclaimerText
  cxSurveyActivation
  cxSurveyId
  cxSurveyUseCaseName
  cxSurveyUseCaseCategory
  biogenLogo
  buttonColor
  emailPrcCode
  eventLocation
  seats
  registrationEndDate
  invitationEmailMayaId
  invitationEmailUTM
  confirmationEmailMayaId
  confirmationEmailUTM
  reminder48hMayaId
  reminder48hUTM
  reminder24hMayaId
  reminder24hUTM
  reminder12hMayaId
  reminder12hUTM
  reminder1hMayaId
  reminder1hUTM
  cxSurveyMayaId
  cxSurveyUTM
  showNavigationBarActions
  showNavigationBarElements
  showFooterNavItems
  screenshotPdfUrl
  emailsScreenshotPdfUrl
  teamContactCheckbox
  teamContactEmail
  teamContactDescription
  reminderNotificationEmails
  reminderNotificationPhoneNumbers
  enableDeclineModal
`;
