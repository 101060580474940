import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ManageCventModal from './AddCventModal';
import useCventGroup from './CventGroup.hook';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Icon, { Icons } from 'yoda-ui/components/Icons/Icon';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CventGroup: FC = () => {
  const { t } = useTranslation();
  const {
    cventGroupConfig,
    handleModalSubmit,
    open,
    setOpen,
    handleReset,
    isCventHasData,
  } = useCventGroup();

  return (
    <FormGroup title={ t('events_cvent_title') } groupName={ CommonGroups.cventConfig }>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...cventGroupConfig.title } disabled />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...cventGroupConfig.sourceUrl } disabled />
      </Box>
      <Button
        startIcon={ <Icon name={ Icons.add } /> }
        buttonType={ ButtonType.secondary }
        onClick={ () => { setOpen(true); } }
      >
        { t('events_modal_cvent_open_btn_label') }
      </Button>
      {
        isCventHasData
        && <Button
          startIcon={ <Icon name={ Icons.refresh } /> }
          buttonType={ ButtonType.secondary }
          onClick={ () => { handleReset(); } }
        >
          { t('actions_reset_label') }
        </Button>
      }
      <ManageCventModal open={ open } setOpen={ setOpen } onSubmit={ handleModalSubmit } />
    </FormGroup>
  );
};

export default CventGroup;
