import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useManageCventModalSchema from './ManageCventModal.schema';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import eventState from 'modules/events/states/events.states';

const useManageCventModalConfig = () => {
  const { t } = useTranslation();
  const snap = useSnapshot(eventState) as EventStateType;
  const manageCventModalSchema = useManageCventModalSchema();

  const manageCventModalConfig = {
    cventTitle: {
      label: t('events_cvent_title_label'),
      name: commonFields.cventTitle,
      placeholder: t('events_cvent_title_placeholder'),
      required: true,
      defaultValue: snap.createEvent?.data?.cventConfig?.title,
      validation: manageCventModalSchema.title,
    },
    sourceUrl: {
      label: t('events_cvent_url_label'),
      name: commonFields.cventSourceUrl,
      placeholder: t('events_cvent_url_placeholder'),
      required: true,
      defaultValue: snap.createEvent?.data?.cventConfig?.sourceUrl,
      validation: manageCventModalSchema.sourceUrl,
    },
  };

  return {
    manageCventModalConfig,
  };
};

export default useManageCventModalConfig;
