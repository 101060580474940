import { Dispatch, FC, SetStateAction } from 'react';
import useManageCventModal from './ManageCventModal.hook';
import DialogForm from 'components/DialogForm';
import Box from 'yoda-ui/components/Box';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

type ManageCventModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (values: { title: string; sourceUrl: string }) => void;
};

const ManageCventModal: FC<ManageCventModalProps> = ({ open, setOpen, onSubmit }) => {
  const {
    manageCventModalConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    t,
  } = useManageCventModal(setOpen, onSubmit);

  return (
    <YodaFormProvider { ...providerFields }>
      <DialogForm
        dialogTitle={ t('events_manage_cvent_form_title') }
        open={ open }
        setOpen={ setOpen }
        cancelButtonConfig={ cancelButtonConfig }
        submitButtonConfig={ submitButtonConfig }
        fullWidth={ true }
      >
        <Box marginBottom={ YodaSpacing.large }>
          <TextYoda
            { ...manageCventModalConfig.cventTitle }
          />
        </Box>
        <Box marginBottom={ YodaSpacing.large }>
          <TextYoda
            { ...manageCventModalConfig.sourceUrl }
          />
        </Box>
      </DialogForm>
    </YodaFormProvider>
  );
};

export default ManageCventModal;
