import { TextButtonProps } from 'yoda-ui/components/BiogenLincDS/Buttons/TextButton/TextButton.types';
import { SelectYodaInputProps } from 'yoda-ui/components/Form/SelectYoda/SelectYoda.types';

export type EventTestingConfig = {
  testUserList: SelectYodaInputProps;
  testButton: TextButtonProps;
  generateEventPagesPdfButton: TextButtonProps;
  insciteEventPagesSyncButton: TextButtonProps;
  generateEmailsPdfButton: TextButtonProps;
  insciteEmailsSyncButton: TextButtonProps;
  addPrivilegedUser: TextButtonProps;
};

export enum ScreenshotPdfType {
  emails = 'emails',
  eventPages = 'eventPages',
}
