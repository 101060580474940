import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import eventState from 'modules/events/states/events.states';

const useCventGroupConfig = () => {
  const { t } = useTranslation();
  const snap = useSnapshot(eventState) as EventStateType;

  const cventGroupConfig = {
    title: {
      label: t('events_cvent_title_label'),
      name: commonFields.cventTitle,
      placeholder: t('events_cvent_title_placeholder'),
      required: false,
      defaultValue: snap.createEvent?.data?.cventConfig?.title,
    },
    sourceUrl: {
      label: t('events_cvent_url_label'),
      name: commonFields.cventSourceUrl,
      placeholder: t('events_cvent_url_placeholder'),
      required: false,
      defaultValue: snap.createEvent?.data?.cventConfig?.sourceUrl,
    },
  };

  return {
    cventGroupConfig,
  };
};

export default useCventGroupConfig;
