import { Market } from 'generated/event.types';
import { LanguageListPerMarket } from 'modules/events/basics/types/events.types';

export const languageListPerMarket: LanguageListPerMarket = {
  [Market.be]: [
    {
      id: 'en-GB',
      label: 'English',
    },
    {
      id: 'fr-BE',
      label: 'French',
    },
    {
      id: 'nl-BE',
      label: 'Dutch',
    },
  ],
  [Market.de]: [
    {
      id: 'de-DE',
      label: 'German',
    },
  ],
  [Market.com]: [
    {
      id: 'en-US',
      label: 'English',
    },
    {
      id: 'fr-CH',
      label: 'French',
    },
    {
      id: 'de-CH',
      label: 'German',
    },
    {
      id: 'hu-HU',
      label: 'Hungarian',
    },
  ],
  [Market.fr]: [
    {
      id: 'fr-FR',
      label: 'French',
    },
  ],
  [Market.uk]: [
    {
      id: 'en-GB',
      label: 'English',
    },
  ],
  [Market.gb]: [
    {
      id: 'en-GB',
      label: 'English',
    },
  ],
  [Market.at]: [
    {
      id: 'de-AT',
      label: 'German',
    },
  ],
  [Market.au]: [
    {
      id: 'en-AU',
      label: 'English',
    },
  ],
  [Market.br]: [
    {
      id: 'pt-BR',
      label: 'Portugese',
    },
  ],
  [Market.ca]: [
    {
      id: 'en-CA',
      label: 'English',
    },
    {
      id: 'fr-CA',
      label: 'French',
    },
  ],
  [Market.es]: [
    {
      id: 'es-ES',
      label: 'Spanish',
    },
  ],
  [Market.ie]: [
    {
      id: 'en-IE',
      label: 'English',
    },
  ],
  [Market.jp]: [
    {
      id: 'ja-JP',
      label: 'Japanese',
    },
  ],
  [Market.nl]: [
    {
      id: 'nl-NL',
      label: 'Dutch',
    },
  ],
  [Market.pl]: [
    {
      id: 'pl-PL',
      label: 'Polish',
    },
  ],
  [Market.dk]: [
    {
      id: 'da-DK',
      label: 'Danish',
    },
  ],
  [Market.us]: [
    {
      id: 'en-US',
      label: 'English',
    },
  ],
  [Market.pt]: [
    {
      id: 'pt-PT',
      label: 'Portuguese',
    },
  ],
  [Market.gulf]: [
    {
      id: 'en-GU',
      label: 'English',
    },
  ],
  [Market.nordics]: [
    {
      id: 'en-NO',
      label: 'English',
    },
    {
      id: 'nb-NO',
      label: 'Norwegian',
    },
    {
      id: 'sv-SE',
      label: 'Swedish',
    },
  ],
  [Market.ar]: [
    {
      id: 'es-AR',
      label: 'Spanish',
    },
    {
      id: 'es-UY',
      label: 'Spanish',
    },
    {
      id: 'es-CL',
      label: 'Spanish',
    },
  ],
};

export const multiLanguageListPerMarket: LanguageListPerMarket = {
  [Market.be]: [
    {
      id: 'fr-BE',
      label: 'French',
    },
    {
      id: 'nl-BE',
      label: 'Dutch',
    },
  ],
  [Market.ch]: [
    {
      id: 'fr-CH',
      label: 'French',
    },
    {
      id: 'de-CH',
      label: 'German',
    },
  ],
  [Market.ca]: [
    {
      id: 'en-CA',
      label: 'English',
    },
    {
      id: 'fr-CA',
      label: 'French',
    },
  ],
  [Market.nordics]: [
    {
      id: 'en',
      label: 'English',
    },
    {
      id: 'nb-NO',
      label: 'Norwegian',
    },
    {
      id: 'sv-SE',
      label: 'Swedish',
    },
  ],
};
