import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { useLazyGetSecretQuery } from 'basics/graphql/queries/getSecret';
import { EventStatus, Market } from 'generated/event.types';
import { previewDomainLinks, prodDomainLinks } from 'modules/events/basics/constants/event.constants';
import { prettifyEventUrl } from 'modules/events/basics/utils/events.utils';
import InfoTag from 'modules/events/components/SideFormDetails/InfoTag';
import StatusBanner from 'modules/events/components/SideFormDetails/StatusBanner';
import eventState from 'modules/events/states/events.states';
import Box from 'yoda-ui/components/Box';
import { YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const sideContainerStyle = {
  root: {
    width: '272px',
  },
  firstBox: {
    backgroundColor: YodaColors.grayLight,
  },
};

const SideContainer: FC = () => {
  const { t } = useTranslation();
  const flags = useFlags();
  const snap = useSnapshot(eventState);
  const { title, market: snapMarket, presence, format, _id: eventId, status, data, externalId } = snap.createEvent;
  const market = data?.isSwitzerlantSpecific ? Market.ch : snapMarket;
  const { queryGetSecret } = useLazyGetSecretQuery();
  const [ceaReportBaseUrl, setCeaReportBaseUrl] = useState('');
  const [ceaReportFilterClause, setCeaReportFilterClause] = useState('');

  useEffect(() => {
    const getCeaSecrets = async () => {
      const { data: { getSecret: baseUrl } } = await queryGetSecret({ variables: { key: 'CEA_REPORT_BASE_URL' } });
      const { data: { getSecret: filterClause } } = await queryGetSecret({ variables: { key: 'СEA_REPORT_FILTER_CLAUSE' } });
      setCeaReportBaseUrl(baseUrl);
      setCeaReportFilterClause(filterClause);
    };

    getCeaSecrets();
  }, [queryGetSecret]);

  const prettyEventTitle = prettifyEventUrl(`${title}_${eventId}`);
  const previewLink = `${previewDomainLinks[market as keyof typeof previewDomainLinks]}/events-list/${prettyEventTitle}`;
  const prodLink = `${prodDomainLinks[market as keyof typeof prodDomainLinks]}/events-list/${prettyEventTitle}`;
  const ceaReport = `${ceaReportBaseUrl + ceaReportFilterClause}'${externalId}'`;

  if (!(format && market && presence)) {
    return null;
  }

  return (
    <Box sx={ sideContainerStyle.root }>
      <Box
        sx={ sideContainerStyle.firstBox }
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding={ YodaSpacing.medium }
      >
        <Box marginBottom={ YodaSpacing.medium }>
          <Box fontSize={ YodaFontSize.small } fontWeight={ YodaFontWeight.light }>
            { t('event_creation_modal_title_field_label') }
          </Box>
          <Box fontSize={ YodaFontSize.large } fontWeight={ YodaFontWeight.medium }>
            { title }
          </Box>
        </Box>
        <Box marginBottom={ YodaSpacing.xxSmall }>
          <InfoTag
            title={ `${t('event_creation_modal_market_field_label')}*` }
            field={ (market && market === Market.gb ? Market.uk : market).toUpperCase() }
          />
          <InfoTag title={ `${t('event_creation_modal_presence_type_field_label')}*` } field={ presence } />
          <InfoTag title={ `${t('event_creation_modal_event_format_field_label')}*` } field={ _.startCase(format) } />
        </Box>
        {
          previewLink && (status === EventStatus.ready || status === EventStatus.published) ? (
            <Box marginBottom={ YodaSpacing.xxSmall }>
              <a href={ previewLink } target='_blank' rel="noreferrer">Preview link</a>
            </Box>
          ) : null
        }
        {
          prodLink && status === EventStatus.published ? (
            <Box marginBottom={ YodaSpacing.xxSmall }>
              <a href={ prodLink } target='_blank' rel="noreferrer">Prod link</a>
            </Box>
          ) : null
        }
        {
          externalId && ceaReport && flags.eventsCweb422EnableLinkToEventCeaReporting && status === EventStatus.published ? (
            <Box marginBottom={ YodaSpacing.xxSmall }>
              <a href={ ceaReport } target='_blank' rel="noreferrer">{ t('eventList_menuItemConfigs_ceaReport_label') }</a>
            </Box>
          ) : null
        }
      </Box>
      <StatusBanner />
    </Box>
  );
};

export default SideContainer;
