import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useManageCventModalConfig from './ManageCventModal.config';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useManageCventModal = (
  setOpen: Dispatch<SetStateAction<boolean>>,
  onSubmit: (values: { title: string; sourceUrl: string }) => void,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { manageCventModalConfig } = useManageCventModalConfig();
  const { t } = useTranslation();
  const { providerFields, getValues, useWatchForm } = useYodaCreateForm();
  const { isValid } = useWatchForm();

  const handleManageCvent = () => {
    setIsSubmitting(true);
    const formValues = getValues();
    onSubmit({
      title: formValues[commonFields.cventTitle],
      sourceUrl: formValues[commonFields.cventSourceUrl],
    });
    setIsSubmitting(false);
    setOpen(false);
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: () => setOpen(false),
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleManageCvent,
      disabled: !isValid,
      buttonType: ButtonType.primary,
    },
    label: t('actions_save_label'),
    loading: isSubmitting,
  };

  return {
    manageCventModalConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    t,
  };
};

export default useManageCventModal;
